<script>
    // -- IMPORTS

    import { fetchData } from '$src/lib/base';
    import { authModalStore } from '$src/lib/store/authModalStore';
    import { languageTagStore } from '$src/lib/store/languageTagStore';
    import { getJsonText, getLocalizedTextBySlug } from 'senselogic-gist';
    import AuthModalSelectCountry from './AuthModalSelectCountry.svelte';
    import { navigate } from 'svelte-routing';
    import ModalButton from '../modal/ModalButton.svelte';
    import AuthFormInput from './AuthFormInput.svelte';

    // -- VARIABLES

    export let activeStep = 0;
    export let isSubmitting = false;
    let authError = null;
    let authMessage = null;
    let signUpStepArray = [ 'phone', 'info', 'sign-in' ];
    let signUpFormData =
        {
            phonePrefix: null,
            phoneNumber: null,
            firstName: null,
            lastName: null,
            email: null
        };
    let termsAccepted = false;

    // -- FUNCTIONS

    function validateSignUpInputs(
        )
    {
        authError = null;

        if ( signUpFormData.email === '' || signUpFormData.email === null )
        {
            authError = 'auth-sign-up-email-failed';
            activeStep = 1;
            return;
        }

        if ( signUpFormData.firstName === '' || signUpFormData.firstName === null )
        {
           authError = 'auth-sign-up-empty-first-name';
           activeStep = 1;
           return;
        }

        if ( signUpFormData.lastName === '' || signUpFormData.lastName === null )
        {
            authError = 'auth-sign-up-empty-last-name';
            activeStep = 1;
            return;
        }

        if ( signUpFormData.phonePrefix === '' || signUpFormData.phonePrefix === null )
        {
            authError = 'auth-country-phone-prefix-error-message';
            activeStep = 0;
            return;
        }

        if ( signUpFormData.phoneNumber === '' || signUpFormData.phoneNumber === null )
        {
            authError = 'auth-phone-number-error-message';
            activeStep = 0;
            return;
        }

        authError = null;
    }

    // ~~

    async function handleSignUp(
        )
    {
        if ( signUpStepArray[ activeStep ] === 'phone' )
        {
            if ( !termsAccepted )
            {
                authError = getLocalizedTextBySlug( 'auth-terms-not-accepted', $languageTagStore );
                return;
            }
            else
            {
                authError = null;
            }

            ++activeStep;
        }
        else if ( signUpStepArray[ activeStep ] === 'sign-in' )
        {
            $authModalStore = null;
        }
        else
        {
            validateSignUpInputs();

            if ( authError === null )
            {
                isSubmitting = true

                try
                {
                    let response = await fetchData(
                        '/api/sign-up',
                        {
                            method: 'POST',
                            body: getJsonText( signUpFormData ),
                            credentials: 'include'
                        }
                        );

                    authMessage = response.message;
                    authError = response.error;
                    activeStep = response.step;
                }
                catch ( error )
                {
                    console.error( 'ERROR:' + error );
                }

                isSubmitting = false;
            }
        }
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    form
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    // -- CLASSES

    .auth-modal-form-container
    {
        border: 1px solid grayColor700;
        border-radius: 1rem;

        background-color: grayColor950;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
    }

    .auth-modal-form-select,
    {
        padding: 0.75rem 1.25rem;
    }

    .auth-modal-form-warning
    {
        margin-bottom: 1rem;
        width: 100%;

        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
    }

    .terms-checkbox
    {
        accent-color: blueColor;
    }
</style>

<form on:submit|preventDefault={ handleSignUp }>
    {#if authError }
        <div class="font-size-90 font-weight-500 color-red">
            {  getLocalizedTextBySlug( authError, $languageTagStore )  }
        </div>
    {/if}
    {#if signUpStepArray[ activeStep ] === 'phone' }
        <div class="auth-modal-form-container">
            <div class="auth-modal-form-select">
                <AuthModalSelectCountry bind:phonePrefix={ signUpFormData.phonePrefix } />
            </div>
            <AuthFormInput
                name="phone-number"
                type="phone-number"
                placeholder="Phone number"
                required={ true }
                bind:value={ signUpFormData.phoneNumber }
            />
        </div>
    {:else if signUpStepArray[ activeStep ] === 'sign-in' }
        {#key authMessage }
            {#if authMessage }
                <div class="font-size-90 font-weight-500 color-green">
                    { getLocalizedTextBySlug( authMessage, $languageTagStore ) }
                </div>
            {/if}
        {/key}
    {:else}
        <div class="auth-modal-form-container">
            <AuthFormInput
                name="first-name"
                placeholder="First Name"
                required={ true }
                bind:value={ signUpFormData.firstName }
            />
            <AuthFormInput
                name="last-name"
                placeholder="Last Name"
                required={ true }
                bind:value={ signUpFormData.lastName }
            />
            <AuthFormInput
                name="email"
                type="email"
                placeholder="Email"
                required={ true }
                bind:value={ signUpFormData.email }
            />
        </div>
    {/if}
    {#if signUpStepArray[ activeStep ] === 'phone' }
        <div class="font-size-75 font-weight-500 color-gray auth-modal-form-warning">
            <input class="terms-checkbox" type="checkbox" bind:checked={ termsAccepted }>
            <button
                on:click=
                    {
                        () =>
                        {
                            navigate( '/terms' );
                            $authModalStore = null;
                        }
                    }
            >
                <div class="font-size-75 font-weight-700 color-blue">
                    { getLocalizedTextBySlug( 'accept-terms-label', $languageTagStore ) }
                </div>
            </button>
        </div>
    {/if}
    <ModalButton
        type="submit"
        text={ getLocalizedTextBySlug( 'auth-continue-button', $languageTagStore ) }
        isLoading={ isSubmitting }
    />
</form>
