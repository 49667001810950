<script>
    // -- IMPORTS

    import { languageTagStore } from '$src/lib/store/languageTagStore';
    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { Link } from 'svelte-routing';

    // -- VARIABLES

    export let text = 'Button';
    export let onClick = () => {};
    export let url = null;
    export let disabled = false;
    export let secondary = false;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    button
    {
        height: 3.125rem;
        border-radius: 0.75rem;
        padding: 0.75rem 2.5rem;

        gap: 0.5rem;

        background-color: blueColor100;

        line-height: 1.5rem;
        font-size: 1rem;
        font-weight: 700;
        color: whiteColor;

        cursor: pointer;
        &:hover
        {
            border-color: blueColor500;

            background-color: blueColor500;
        }

        &:active
        {
            border-color: blueColor300;

            background-color: blueColor300;
        }

        &:disabled
        {
            border-color: blueColorTransparent90;

            background-color: blueColorTransparent90;

            cursor: not-allowed;
        }

        &.secondary
        {
            background-color: transparent;

            color: blueColor300;
            &:hover
            {
                background-color: blueColor500;

                color: blueColor500;
            }

            &:active
            {
                background-color: blueColor300;

                color: blueColor300;
            }

            &:disabled
            {
                background-color: blueColorTransparent90;

                color: blueColorTransparent90;

                cursor: not-allowed;
            }
        }

        +media( desktop )
        {
            height: 3.25rem;
        }
    }
</style>

{#if url }
    <Link to={ url }>
        <button
            disabled={ disabled }
            class:secondary={ secondary }
            on:click={ onClick }
        >
            { getLocalizedTextBySlug( text, $languageTagStore ) }
        </button>
    </Link>
{:else}
    <button
        disabled={ disabled }
        class:secondary={ secondary }
        on:click={ onClick }
    >
        { getLocalizedTextBySlug( text, $languageTagStore ) }
    </button>
{/if}
