<script>
    // -- VARIABLES

    export let type = 'text';
    export let name = '';
    export let placeholder = '';
    export let required = false;
    export let value = null;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';

    // -- CLASSES

    .auth-form-input
    {
        padding: 1rem 1.25rem;
    }

    .auth-form-input input,
    .auth-form-input input:-internal-autofill-selected,
    .auth-form-input input:-webkit-autofill,
    .auth-form-input input:-webkit-autofill:hover,
    .auth-form-input input:-webkit-autofill:focus,
    .auth-form-input input:-webkit-autofill:active
    {
        width: 100%;

        background-color: grayColor950 !important;
        -webkit-background-clip: text;
        -webkit-text-fill-color: grayColor100;
        box-shadow: inset 0 0 20px 20px grayColor950;

        color: blackColor !important;

        transition: background-color 5000s ease-in-out 0s;
    }

    .auth-form-input input:focus-visible
    {
        outline: unset;
    }
</style>

<div class="auth-form-input">
    <input
        { ...{ type } }
        name={ name }
        placeholder={ placeholder }
        required={ required }
        bind:value={ value }
    />
</div>
