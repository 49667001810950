<script>
    // -- IMPORTS

    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { languageTagStore } from '$src/lib/store/languageTagStore';
    import { googleAuthUrl } from '$src/lib/base';
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .auth-modal-sepator
    {
        display: flex;
        justify-content: center;
        align-items: center;

        white-space: nowrap;
    }

    .auth-modal-sepator::before,
    .auth-modal-sepator::after
    {
        height: 1px;

        flex-grow: 1;

        content: '';
        background-color: lightGrayBorderColor;
    }

    .auth-modal-sepator::before
    {
        margin-right: 0.75rem;
    }

    .auth-modal-sepator::after
    {
        margin-left: 0.75rem;
    }

    .auth-modal-socials
    {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem
    }

    .auth-modal-socials-item
    {
        border: 1px solid grayColor700;
        border-radius: 1rem;
        padding: 1rem;

        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        background-color: grayColor950;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
    }
</style>

<div class="font-size-100 font-weight-500 color-black  auth-modal-sepator">
    { getLocalizedTextBySlug('auth-or-label', $languageTagStore ) }
</div>
<div class="auth-modal-socials">
    <!-- <div class="auth-modal-socials-item" style="cursor: not-allowed; background: red;">
        <span class="facebook-logo-icon size-150"></span>
    </div> -->
    <a class="auth-modal-socials-item" href="{ googleAuthUrl }">
        <span class="google-logo-icon size-150"></span>
    </a>
</div>
