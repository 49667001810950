<script>
    // -- IMPORTS

    import { languageTagStore } from '$src/lib/store/languageTagStore';
    import { hostUrl } from '$src/lib/base';
    import { authModalStore } from '$src/lib/store/authModalStore';
    import { profileSignedInStore } from '$src/lib/store/profileStore';
    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { navigate } from 'svelte-routing';
    import ModalButton from '../modal/ModalButton.svelte';
    import AuthFormInput from './AuthFormInput.svelte';

    // -- VARIABLES

    export let activeStep = 0;
    export let isSubmitting = false;
    let authError = null;
    let authMessage = null;
    let signInStepArray = [ 'request-token', 'request-token-message', 'sign-in' ];
    let signUpFormData =
        {
            email: null
        }

    // -- FUNCTIONS

    async function handleSignIn(
        event
        )
    {
        let formData = new FormData( event.target );
        authError = null;

        if ( signInStepArray[ activeStep ] === 'request-token' )
        {
            isSubmitting = true;

            let response = await fetch(
                hostUrl + '/api/request-token',
                {
                    method: 'POST',
                    body: JSON.stringify( Object.fromEntries( formData ) )
                }
                );

            if ( response.ok )
            {
                ++activeStep;
            }

            let res = await response.json();

            authMessage = res.message;
            authError = res.error;

            isSubmitting = false;
        }
        else if ( signInStepArray[ activeStep ] === 'request-token-message' )
        {
            ++activeStep;
        }
        else
        {
            isSubmitting = true;

            let response = await fetch(
                hostUrl + '/api/sign-in',
                {
                    method: 'POST',
                    body: JSON.stringify( { ...Object.fromEntries( formData ), email: signUpFormData.email } ),
                    credentials: 'include'
                }
                );

            if ( response.ok )
            {
                $profileSignedInStore = await response.json();
                $authModalStore = null;
            }
            else
            {
                let res = await response.json();

                authMessage = res.message;
                authError = res.error;
            }

            isSubmitting = false;
        }
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    form
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    // -- CLASSES

    .auth-modal-form-container
    {
        border: 1px solid grayColor700;
        border-radius: 1rem;

        background-color: grayColor950;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
    }

    .auth-modal-form-warning
    {
        margin-bottom: 1rem;
        width: 100%;

        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
    }
</style>

<form on:submit|preventDefault={ handleSignIn }>
    {#if authError }
        <div class="font-size-90 font-weight-500 color-red">
            { getLocalizedTextBySlug( authError, $languageTagStore ) }
        </div>
    {/if}
    {#if signInStepArray[ activeStep ] === 'request-token' }
        <div class="auth-modal-form-container">
            <AuthFormInput
                name="email"
                type="email"
                placeholder="Email"
                required={ true }
                bind:value={ signUpFormData.email }
            />
        </div>
    {:else if signInStepArray[ activeStep ] === 'request-token-message' }
        {#if authMessage }
            <div
                class="font-size-90 font-weight-500 color-green"
            >
                { getLocalizedTextBySlug( authMessage, $languageTagStore ) }
            </div>
        {/if}
    {:else if signInStepArray[ activeStep ] === 'sign-in' }
        <div class="auth-modal-form-container">
            <AuthFormInput
                name="token"
                placeholder="Enter token"
                required={ true }
            />
        </div>
    {/if}
    <div class="font-size-75 font-weight-500 color-gray auth-modal-form-warning">
        <button
            type="button"
            on:click=
                {
                    () =>
                    {
                        navigate( '/terms' );
                        $authModalStore = null;
                    }
                }
        >
            <div class="font-size-75 font-weight-700 color-blue">
                { getLocalizedTextBySlug( 'blog-terms-label', $languageTagStore ) }
            </div>
        </button>
    </div>
    <ModalButton
        type="submit"
        text={ getLocalizedTextBySlug( 'auth-continue-button', $languageTagStore ) }
        isLoading={ isSubmitting }
    />
</form>
