// -- IMPORTS

import { writable } from 'svelte/store';

// -- CONSTANTS

const maxWidthInEmMediaScreen = window.matchMedia( '(max-width: 56em)' );

// -- VARIABLES

export let appDataStore = writable( null );
export let isMobileScreen = writable( maxWidthInEmMediaScreen.matches );

// -- FUNCTIONS

export function handleResizeEvent(
    )
{
    isMobileScreen.set( maxWidthInEmMediaScreen.matches );
}
