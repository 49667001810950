<script>
    // -- IMPORTS

    import { getLocalizedText, getLocalizedTextBySlug } from 'senselogic-gist';
    import { getLocalizedMonthDayTextFromDateText } from '$lib/base';
    import { languageTagStore } from '$store/languageTagStore';
    import { totalGuestCounterStore } from '$store/bookingStore';
    import { isDesktopMapOpenStore, isDesktopListOpenStore, isMobileMapOpenStore, isMobileListOpenStore } from '$store/locationStore';
    import PropertiesSearchPopup from '$component/page/properties/PropertiesSearchPopup.svelte';
    import PropertiesFilterPopup from '$component/page/properties/PropertiesFilterPopup.svelte';

    // -- VARIABLES

    export let selectedCity;
    export let selectedCountry;
    export let arrivalDate;
    export let departureDate;

    let selectedCityName = selectedCity ? selectedCity.name : '';
    let selectedCountryName = selectedCountry ? selectedCountry.name : '';
    let showPropertiesSearchPopup = false;
    let showPropertiesFilterPopup = false;

    // -- STATEMENTS

    $: selectedCityName = selectedCity ? selectedCity.name : '';
    $: selectedCountryName = selectedCountry ? selectedCountry.name : '';
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .properties-filter-search
    {
        position: relative;

        overflow: hidden;
        height: 3.25rem;
        width: 100%;
        max-width: 20.5rem;

        display: flex;
        justify-content: center;
    }

    .properties-filter-search-bar
    {
        z-index: 1;

        width: 100%;
        border-radius: 2rem;
        padding: 0.75rem 1rem;

        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        justify-content: space-between;
        align-items: center;

        background-color: grayColor950;

        +media( desktop )
        {
            max-width: 30rem;
        }
    }

    .properties-filter-search.is-desktop-list-open .properties-filter-search-bar,
    .properties-filter-search.is-desktop-map-open .properties-filter-search-bar
    {
        +media( desktop )
        {
            max-width: 30rem;
        }
    }

    .properties-filter-search.is-desktop-map-open.is-desktop-list-open .properties-filter-search-bar
    {
        +media( desktop )
        {
            max-width: 100%;
        }
    }

    .properties-filter-search-bar-search,
    .properties-filter-search-bar-filter
    {
        flex-shrink: 0;

        background-color: greenColor500;
    }

    .properties-filter-search-bar-search-container
    {
        height: auto;
        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0.75rem;
        justify-content: space-between;
        align-items: center;
    }

    .properties-filter-search-bar-filter-container
    {
        height: 100%;
        border-left: 1px solid grayColor800;
        padding-left: 1rem;

        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
    }

    .properties-filter-search-bar-location-date
    {
        overflow: hidden;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .properties-filter-search-bar-location.is-filled
    {
        color: blueColor;
    }
</style>

<div
    class="properties-filter-search"
    class:is-mobile-map-open={ $isMobileMapOpenStore }
    class:is-mobile-list-open={ $isMobileListOpenStore }
    class:is-desktop-map-open={ $isDesktopMapOpenStore }
    class:is-desktop-list-open={ $isDesktopListOpenStore }
>
    <div class="properties-filter-search-bar">
        <button
            class="properties-filter-search-bar-search-container"
            on:click={ () => ( showPropertiesSearchPopup = !showPropertiesSearchPopup ) }
        >
            <div class="gray-search-icon size-150 properties-filter-search-bar-search" />
            <div class="properties-filter-search-bar-location-date">
                <div
                    class="font-size-90 color-gray-400 properties-filter-search-bar-location"
                    class:is-filled={ selectedCountryName || selectedCityName }
                >
                    {#if selectedCountryName && selectedCityName }
                        { getLocalizedText( selectedCityName, $languageTagStore ) },
                        { getLocalizedText( selectedCountryName, $languageTagStore ) }
                    {:else if selectedCountryName }
                        { getLocalizedText( selectedCountryName, $languageTagStore ) }
                    {:else if selectedCityName }
                        { getLocalizedText( selectedCityName, $languageTagStore ) }
                    {:else}
                        { getLocalizedTextBySlug( 'filter-your-search-label', $languageTagStore ) }
                    {/if}
                </div>
                {#if arrivalDate && departureDate }
                    <div class="font-size-75 font-weight-500 color-gray-400 properties-filter-search-bar-date">
                        { getLocalizedMonthDayTextFromDateText( arrivalDate ) }
                        - { getLocalizedMonthDayTextFromDateText( departureDate ) }
                        {#if $totalGuestCounterStore }
                            &#x2022; { $totalGuestCounterStore }
                            { getLocalizedTextBySlug( 'filter-guests-label', $languageTagStore ) }
                        {/if}
                    </div>
                {/if}
            </div>
        </button>
        <button
            class="properties-filter-search-bar-filter-container"
            on:click={ () => ( showPropertiesFilterPopup = !showPropertiesFilterPopup ) }
        >
            <div class="green-filter-icon size-150 properties-filter-search-bar-filter">
            </div>
            <div class="font-size-85 color-gray-400 properties-filter-search-bar-location">
                { getLocalizedTextBySlug( 'filter-your-filters-label', $languageTagStore ) }
            </div>
        </button>
    </div>
    {#if showPropertiesSearchPopup }
        <div class="modal-overlay"></div>
        <PropertiesSearchPopup
            selectedCity={ selectedCity }
            arrivalDate={ arrivalDate }
            departureDate={ departureDate }
            bind:selectedCityName={ selectedCityName }
            bind:selectedCountryName={ selectedCountryName }
            on:outsideClick={ () => ( showPropertiesSearchPopup = !showPropertiesSearchPopup ) }
        />
    {/if}
    {#if showPropertiesFilterPopup }
        <div class="modal-overlay"></div>
        <PropertiesFilterPopup
            selectedCity={ selectedCity }
            on:outsideClick={ () => ( showPropertiesFilterPopup = !showPropertiesFilterPopup ) }
        />
    {/if}
</div>
